import React from "react";
import logo from "../img/Ardsley-A.png";

const ArdsleyTitle = ({ text }) => {
  return (
    <div
      style={{
        display: "table",
        paddingBottom: "25px",
      }}
    >
      <span
        style={{
          verticalAlign: "middle",
          display: "table-cell",
          textAlign: "left",
          width: "50px",
        }}
      >
        <img src={logo} alt="Ardsley" />
      </span>
      <span
        style={{
          verticalAlign: "middle",
          display: "table-cell",
          textAlign: "left",
        }}
      >
        <h2
          style={{
            paddingLeft: "15px",
            fontSize: "24px",
            fontWeight: "bold",
          }}
        >
          {text}
        </h2>
      </span>
    </div>
  );
};

export default ArdsleyTitle;
