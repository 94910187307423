import React, { useState } from "react";
import { navigate } from "gatsby-link";
import Layout from "../../components/Layout";
import ArdsleyTitle from "../../components/ArdsleyTitle"
import axios from "axios";

export default function Contact() {
    const [name, setName] = useState("");
    const [contactMethod, setContactMethod] = useState("Text");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [confEmail, setConfEmail] = useState("");
    const [body, setBody] = useState("");

    const contactMethods = ['Text', 'Call', 'Email']

    function submitForm() {
        const payload = { name, contactMethod, phoneNumber, email, body };

        console.log("Submitting form", payload);

        // axios.post("https://api.jasonstillerman.com/sendEmail", payload);
        // axios.post("http://localhost:3000/sendEmail", payload);
        axios.post("https://uaftw23499.execute-api.us-east-2.amazonaws.com/default/handleEmail", payload, {
            headers: {
                'Content-Type': 'text/plain'
            }
        });

        navigate("/inquire/thanks");
    }

    function getAdditionalMessage() {
        let phoneValid = isValidPhoneNumber(phoneNumber)
        let emailValid = isValidEmail(email)

        if (!phoneValid && !emailValid) {
            return "(It appears you have entered an invalid email address and phone number.)"
        }

        if (!phoneValid) {
            return "(It appears you have not entered a valid phone number. Make sure you include all 10 digits.)"
        }

        if (!emailValid) {
            return "(It appears you have not entered a valid email address.)"
        }

        return ""
    }

    function isValidPhoneNumber(number) {
        var phoneno = /^\d{10}$/;
        return number.replace(/\D/g, '').match(phoneno)
    }

    function isValidEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    function isValid() {
        const nameValid = name && name.length > 0;
        const phoneValid = phoneNumber && phoneNumber.length > 0;
        const emailValid = email && email.length > 0;
        const bodyValid = body && body.length > 0;

        const conf = email == confEmail;

        // console.log({nameValid, phoneVali});

        return emailValid && phoneValid && nameValid && bodyValid && conf;
    }

    return (
        <Layout>
            <div className="container">
                <div className="section">
                    <div className="columns">
                        <div className="column">
                            <a href="tel:1-518-272-1122" style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>518.272.1122</a>

                            <p style={{ fontWeight: 'bold', paddingTop: '5px' }}>A select Starr & Company property</p>
                            <p style={{ fontSize: '0.75rem', fontWeight: '400' }}>Member, Historic Properties Network</p>
                            <br />

                            <img src="/img/white-ardsley-sketch.jpg" />
                        </div>

                        <div className="column" style={{ backgroundColor: 'white', border: 'solid', borderWidth: '1px', borderColor: 'lightgrey' }}>
                            <ArdsleyTitle text="Inquire" />
                            <div className="field">
                                <label className="label">Full Name*</label>
                                <div className="control">
                                    <input className="input" placeholder="John Smith" value={name} onChange={(e) => setName(e.target.value)} />
                                </div>
                            </div>

                            <div className="field">
                                <label className="label">Preferred Method of Contact*</label>
                                <div className="control">
                                    <div className="select">
                                        <select value={contactMethod} onChange={e => setContactMethod(e.target.value)}>
                                            {
                                                contactMethods.map(method => <option key={method}>{method}</option>)
                                            }
                                        </select>
                                    </div>

                                    {/* <input className="input" value={contactMethod} onChange={(e) => setContactMethod(e.target.value)} /> */}
                                </div>
                            </div>

                            <div className="field">
                                <label className="label">Phone*</label>
                                <div className="control">
                                    <input className="input" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                                </div>
                            </div>

                            <div className="field">
                                <label className="label">Email*</label>
                                <div className="control">
                                    <input className="input" value={email} onChange={(e) => setEmail(e.target.value)} />
                                </div>
                            </div>

                            <div className="field">
                                <label className="label">Confirm Email*</label>
                                <div className="control">
                                    <input className="input" value={confEmail} onChange={(e) => setConfEmail(e.target.value)} />
                                </div>
                            </div>

                            <div className="field">
                                <label className="label">Message*</label>
                                <div className="control">
                                    <textarea className="textarea" value={body} onChange={(e) => setBody(e.target.value)} />
                                </div>
                            </div>

                            <p style={{ fontSize: '0.75rem', fontWeight: 'light' }}>* Information provided herein is held in strict confidence and not distributed by Starr & Company Properties LLC, its vendors or affiliates.</p>
                            <br />
                            <div className="field">
                                <div className="control">
                                    <button className="button is-link" disabled={!isValid()} onClick={submitForm} style={{ opacity: isValid() ? undefined : 0.5 }}>{"Send"}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout >
    );
}
